import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';

// Components
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// Pages
import PaymentForm from 'pages/secure/profile/intra-page-items/_payment.item';

// Types
import { CreditCardPayload } from 'state/account/account.services';
// Modals
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Utils
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';
import { addZero } from 'util/number';
import { getCreditCardEnding } from 'util/payments';
import { convertToTitleCase } from 'util/string';

// Styles
import './checkout-payment-methods.style.scss';

interface CheckoutPaymentMethodsProps {
    selectedPaymentMethod?: CreditCardPayload;
    creditCardsData?: CreditCardPayload[];
    isInnerWidthViewport?: MutableRefObject<boolean>;
    onChange: () => void;
    title?: string;
    shouldHidePaymentType?: boolean;
    error?: string;
}

const CheckoutPaymentMethods: React.FC<CheckoutPaymentMethodsProps> = (props) => {
    const {
        selectedPaymentMethod,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        creditCardsData,
        isInnerWidthViewport,
        onChange,
        title,
        shouldHidePaymentType = false,
        error
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAddNewPaymentClick = () => {
        // DRX-2675: This feature would use this modal to update the payment methods,
        // but in matter to implement the current functionality it would be redirected to profile payments.
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <PaymentForm
                                centerFormSubmit={true}
                                isInModal={false}
                                onCancel={() => {
                                    dispatch(closeModal({}));
                                }}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    return (
        <>
            {selectedPaymentMethod ? (
                <div className="checkout-payment">
                    {title && (
                        <div className="checkout-payment-title-container">
                            <h6>{title}</h6>
                        </div>
                    )}
                    <div className="checkout-payment-container">
                        {selectedPaymentMethod && (
                            <div className="selected-payment">
                                {!shouldHidePaymentType && (
                                    <div className="selected-payment-type">
                                        <small>
                                            {t(
                                                `components.medicineCabinetCart.paymentMethod.${
                                                    selectedPaymentMethod.defaultCard
                                                        ? 'primaryPayment'
                                                        : 'secondaryPayment'
                                                }`
                                            )}
                                        </small>
                                    </div>
                                )}
                                <div className="selected-payment-info">
                                    <div className="selected-payment-info-text">
                                        <p>{`${
                                            isInnerWidthViewport?.current
                                                ? ellipsify(lowercaseAndCapitalize(selectedPaymentMethod.cardType))
                                                : selectedPaymentMethod.cardType
                                        } ${getCreditCardEnding(selectedPaymentMethod.cardNumber, '····')}`}</p>
                                        <p>
                                            {convertToTitleCase(
                                                `${selectedPaymentMethod?.cardFirstName} ${selectedPaymentMethod?.cardLastName}`
                                            )}
                                        </p>
                                        <p>
                                            {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                                month: addZero(selectedPaymentMethod.cardMonthNum),
                                                year: selectedPaymentMethod.cardYear.slice(-2)
                                            })}
                                        </p>
                                    </div>
                                    <CreditCardIcon
                                        className="selected-payment-info-icon"
                                        variant={selectedPaymentMethod.cardType}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="btn-container">
                            <Button
                                type="button"
                                variant="text"
                                className="p-0 text-cerulean no-min-width btn-demi"
                                label={t('pages.profile.membership.manageMembership.changeBtn')}
                                onClick={onChange}
                                dataGAFormName="CheckoutPaymentMethods"
                                dataGAFormStepName="Checkout"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="checkout-payment-cta">
                    <div className="checkout-payment-error text-left">{error && <p>{error}</p>}</div>

                    <Button
                        plusIcon
                        IconType="secondary"
                        className="p-2 text-uppercase btn-bold justify-content-lg-end justify-content-sm-start"
                        dataGAFormName="Payments"
                        label={t('pages.profile.membership.manageMembership.addNewPayment')}
                        onClick={handleAddNewPaymentClick}
                        type="button"
                        variant="text-blue"
                    />
                </div>
            )}
        </>
    );
};

export default CheckoutPaymentMethods;
