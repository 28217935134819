import { graphql, navigate } from 'gatsby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import BadgeStatus from 'ui-kit/bagde/badge-status';
import Button from 'ui-kit/button/button';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Display Components
import ManageGroupCardList from 'display-components/membership/manage-group-card-list';
import { MembershipSettingsErrorModal } from 'display-components/membership/modals';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import HealthConditions from 'components/cart/health-conditions/health-conditions.component';
import CheckoutPaymentMethods from 'components/checkout/checkout-payment-methods';
import CheckoutSectionHeader from 'components/checkout/checkout-section-header';
import CheckoutLayout from 'components/layouts/checkout';
import ManageMembershipPaymentMethodBody from 'components/manage-membership/manage-membership-payment-method-body';

// State
import { accountFetchProfileRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountProfileMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';
import { membershipActions } from 'state/membership/membership.reducers';
import {
    membershiGenerateScheduleLinkRoutine,
    membershipDetailsRoutine,
    membershipPreAuthorizePaymentRoutine,
    membershipRequestVisitMemberIdRoutine
} from 'state/membership/membership.routines';
import {
    isMembershipAvailableSelector,
    membershipDetailSelector,
    membershipIsCarePlanSelector,
    membershipIsLoadingDetailsSelector,
    membershipIsOnDemandSelector,
    membershipIsOwnerSelector,
    membershipIsTelemedicineAvailableSelector,
    membershipRequestVisitMemberIdSelector,
    membershipRequiresPaymentSelector,
    membershipVisitSelectedCardSelector
} from 'state/membership/membership.selector';

// Schema
import { formatPrice } from 'schema/price.schema';

// Types & interfaces
import {
    ManageGroupCardProps,
    MembershipGenerateScheduleLinkResponse,
    MembershipMembers,
    MembershipPreAuthorizePaymentResponse
} from 'types/membership';

// Utils
import { isAxiosError } from 'util/axiosClient';
import { convertToTitleCase } from 'util/string';

// Hooks
import { useHealthConditions } from 'hooks/useHealthConditions';

// Styles
import './request-visit-page.style.scss';

const RequestVisitPage = ({ data }: { data: GatsbyTypes.RequestVisitPageDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { backgroundImage } = data;

    const initialTitle = t('pages.requestTelemedicineVisit.healthProfile.title');

    // Local State
    const [selectedMemberID, setSelectedMemberID] = useState<string | null>();
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);
    const [membershipMembersList, setMembershipMembersList] = useState<MembershipMembers[]>([]);
    const [healthProfileData, setHealthProfileData] = useState({
        initialTitle,
        patientEpostPatientNum: ''
    });
    const [isReviewed, setIsReviewed] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [isBusy, setIsBusy] = useState<boolean>(false);

    // Selectors
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const { membershipID } = useSelector(accountProfileMembershipSelector);
    const membershipObject = useSelector(membershipDetailSelector);
    const loadingDetails = useSelector(membershipIsLoadingDetailsSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const isPlanOwner = useSelector(membershipIsOwnerSelector);
    const requestVisitMemberIdSelected = useSelector(membershipRequestVisitMemberIdSelector);
    const hasVisitAvailable = useSelector(membershipIsTelemedicineAvailableSelector);
    const profileObject = useSelector(accountProfileSelector);
    const selectedCardForVisit = useSelector(membershipVisitSelectedCardSelector);
    const isCarePlan = useSelector(membershipIsCarePlanSelector);
    const isMembershipPaymentRequired = useSelector(membershipRequiresPaymentSelector);
    const isMembershipAvailable = useSelector(isMembershipAvailableSelector);
    const isLoadingMembershipHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const isLoadingAllergies = useSelector(isLoadingAllergiesSelector);

    // Hooks
    const { existingAllergies, existingConditions, userHasNotSubmittedAllergies, userHasNotSubmittedConditions } =
        useHealthConditions(true);

    const activeCreditCards = useMemo(() => {
        return allPaymentData?.filter((card) => card.cardActive);
    }, [allPaymentData]);

    const defaultCreditCard = useMemo(() => {
        return activeCreditCards?.find((card) => card.defaultCard);
    }, [activeCreditCards]);

    const isActiveCreditCard = ({ cardSeqNum }: { cardSeqNum: string }): boolean => {
        return Boolean(activeCreditCards.some((active) => cardSeqNum === active.cardSeqNum));
    };

    useEffect(() => {
        if (!isMembershipAvailable) {
            navigate('/secure/medicine-cabinet');
        }
    }, [isMembershipAvailable]);

    useEffect(() => {
        if (isMembershipPaymentRequired) {
            navigate('/secure/membership');
        }
    }, [isMembershipPaymentRequired]);

    useEffect(() => {
        if (isReviewed === true) {
            setError('');
        }
    }, [isReviewed]);

    useEffect(() => {
        if (activeCreditCards && activeCreditCards.length > 0 && defaultCreditCard) {
            setIsReviewed(true);
            setError('');
        } else {
            setIsReviewed(false);
        }
    }, [activeCreditCards, defaultCreditCard]);

    useEffect(() => {
        if (profileObject === undefined) {
            dispatch(accountFetchProfileRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (profileObject) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [dispatch, profileObject]);

    useEffect(() => {
        // assign the default card as the selected payment method when:
        // 1) It's the only payment method available
        // 2) The selected card is no longer an active credit card
        if (
            (defaultCreditCard !== undefined && !selectedCardForVisit) ||
            (selectedCardForVisit && !isActiveCreditCard(selectedCardForVisit))
        ) {
            dispatch(membershipActions.setSelectedPaymentForVisit(defaultCreditCard));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCreditCard]);

    useEffect(() => {
        return () => {
            // remove the selected memberId when the component is unmounted
            dispatch(membershipRequestVisitMemberIdRoutine.trigger(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (membershipObject?.members) {
            const filteredMembers =
                membershipObject.members
                    .filter((member) => ['active', 'accepted'].includes(member.status.toLowerCase()))
                    .filter((member) => {
                        // it will skip filtering if the member is the same as the profile
                        if (membershipObject?.epostPatientNum === profileObject?.epostPatientNum) {
                            return true;
                        }

                        // if the member is not the same as the profile, it will filter it
                        if (member.epostPatientNum !== profileObject?.epostPatientNum) {
                            return false;
                        }
                        return true;
                    }) || [];

            setMembershipMembersList(filteredMembers);

            if (filteredMembers.length === 1) {
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(membershipID));
            }

            if (filteredMembers.length < 2) {
                setSelectedMemberID(membershipID);
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(membershipID));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, membershipID, membershipObject]);

    useEffect(() => {
        if (!membershipObject && membershipID) {
            dispatch(membershipDetailsRoutine.trigger(membershipID));
        }
    }, [dispatch, membershipID, membershipObject]);

    useEffect(() => {
        if (membershipMembersList.length === 1) {
            dispatch(
                medicalConditionsDetailsRoutine.trigger({
                    epostPatientNumFamily: profileObject?.epostPatientNum
                })
            );
            dispatch(
                medicalConditionsAllergiesDetailsRoutine.trigger({
                    epostPatientNumFamily: profileObject?.epostPatientNum
                })
            );
            setHealthProfileData({
                initialTitle,
                patientEpostPatientNum: profileObject?.epostPatientNum as string
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, membershipMembersList, profileObject]);

    const handleSelectMember = useCallback(
        (memberID: string) => {
            setSelectedMemberID(memberID);
            setIsErrorMessageVisible(false);

            // If the user already selected an user, it should be able to change the user only by selecting another one.
            if (requestVisitMemberIdSelected) {
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(memberID));
            }
        },
        [dispatch, requestVisitMemberIdSelected]
    );

    const handleSubmitSelectedMember = useCallback(() => {
        if (!selectedMemberID) {
            setIsErrorMessageVisible(true);
            return;
        }
        dispatch(membershipRequestVisitMemberIdRoutine.trigger(selectedMemberID));
    }, [dispatch, selectedMemberID]);

    const fetchMedicalConditions = (ePostPatientNum: string) => {
        if (ePostPatientNum && ePostPatientNum.length > 0) {
            dispatch(
                medicalConditionsDetailsRoutine.trigger({
                    epostPatientNumFamily: ePostPatientNum
                })
            );
            dispatch(
                medicalConditionsAllergiesDetailsRoutine.trigger({
                    epostPatientNumFamily: ePostPatientNum
                })
            );
        }
    };

    const inputRadioElement = (memberID: string, ePostPatientNum: string, memberName?: string, index?: number) => (
        <div className="manage-group-card__content__radio">
            <input
                type="radio"
                name={`option-member`}
                value={memberID}
                id={`option-member-${memberID}`}
                className="manage-group-card__content__input"
                onChange={() => handleSelectMember(ePostPatientNum)}
                onClick={() => {
                    fetchMedicalConditions(ePostPatientNum);
                    if (memberName && index !== 0) {
                        setHealthProfileData({
                            initialTitle: `${initialTitle} of ${memberName}`,
                            patientEpostPatientNum: ePostPatientNum
                        });
                    } else {
                        setHealthProfileData({
                            initialTitle,
                            patientEpostPatientNum: ePostPatientNum
                        });
                    }
                }}
            />
        </div>
    );

    const formatPhoneNumber = (phoneNumber: string): string => {
        const numericOnly = phoneNumber.replace(/\D/g, '');
        const match = numericOnly.match(/^(\d{3})(\d{3})(\d{4})$/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
    };

    // Members to be shown in the card
    const membersPayload: ManageGroupCardProps[] = membershipMembersList.map((member, index) => {
        const memberName = `${convertToTitleCase(member.firstName)} ${convertToTitleCase(member.lastName)}`;
        return {
            memberName,
            accountType: index === 0 ? (t('pages.requestTelemedicineVisit.currentUser') as 'You') : member.accountType,
            email: member.email,
            phoneNumber: formatPhoneNumber(member.phoneNumber),
            showRadioButton: membershipMembersList.length > 1,
            inputRadioElement:
                membershipMembersList.length > 1
                    ? inputRadioElement(String(member.membershipId), member.epostPatientNum, memberName, index)
                    : undefined,
            isMinorDisclaimerVisible: selectedMemberID === String(member.epostPatientNum)
        };
    });

    const onChangePaymentMethod = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'modal-membership-payment',
                size: 'lg',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('pages.profile.membership.manageMembership.modals.changePaymentTitle')}
                        subTitle={t('pages.profile.membership.manageMembership.modals.changePaymentSubtitle')}
                        body={
                            <ManageMembershipPaymentMethodBody
                                isModal={true}
                                isRequestVisit={true}
                                selectedPaymentForVisit={selectedCardForVisit}
                            />
                        }
                    />
                )
            })
        );
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const showGenericErrorModal = useCallback(
        (
            memberName: string,
            variant:
                | 'sendInvitation'
                | 'addMember'
                | 'sendInvitationAssociated'
                | 'generateScheduleLink'
                | 'paymentError'
        ) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => {
                        dispatch(closeModal({}));
                        // Delaying to enable the CTA to prevent unneeded calls
                        setTimeout(() => {
                            setIsBusy(false);
                        }, 800);
                    },
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation={variant}
                            memberName={convertToTitleCase(memberName)}
                            t={t}
                            onClose={() => {
                                dispatch(closeModal({}));
                                // Delaying to enable the CTA to prevent unneeded calls
                                setTimeout(() => {
                                    setIsBusy(false);
                                }, 500);
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const handleOnSubmit = (epostPatientNum: string, cardSeqNum: string) => {
        setIsBusy(true);

        if (!isReviewed) {
            setError(t('pages.profile.membership.manageMembership.errors.paymentMethod'));
            setIsBusy(false);
            return;
        } else {
            setError('');
        }
        if (userHasNotSubmittedAllergies || userHasNotSubmittedConditions) return;

        if (hasVisitAvailable) {
            dispatch(
                membershiGenerateScheduleLinkRoutine.trigger({
                    data: {
                        epostPatientNumber: epostPatientNum,
                        cardSeqNum
                    },
                    onSuccess: (response: MembershipGenerateScheduleLinkResponse) => {
                        navigate(response.patientLoginLinkSteadyMD.link);
                    },
                    onFailure: (errors: MembershipGenerateScheduleLinkResponse) => {
                        showGenericErrorModal('', 'generateScheduleLink');
                    }
                })
            );
        } else {
            dispatch(
                membershipPreAuthorizePaymentRoutine.trigger({
                    payload: {
                        EPostPatientNum: profileObject?.epostPatientNum,
                        CardSeqNum: cardSeqNum
                    },
                    onSuccess: (response: MembershipPreAuthorizePaymentResponse) => {
                        if (response.isPaymentAuthorized === true) {
                            dispatch(
                                membershiGenerateScheduleLinkRoutine.trigger({
                                    data: {
                                        epostPatientNumber: epostPatientNum,
                                        cardSeqNum: cardSeqNum
                                    },
                                    onSuccess: (response: MembershipGenerateScheduleLinkResponse) => {
                                        navigate(response.patientLoginLinkSteadyMD.link);
                                    },
                                    onFailure: (errors: MembershipGenerateScheduleLinkResponse) => {
                                        showGenericErrorModal('', 'generateScheduleLink');
                                    }
                                })
                            );
                        } else {
                            setIsBusy(false);
                        }
                    },
                    onFailure: (errors: unknown) => {
                        // DRX-3319: Since users might encounter various related errors (such as connection errors),
                        // This validation ensures that if the API response fails, the appropriate modal is displayed.
                        if (isAxiosError(errors)) {
                            showGenericErrorModal('', 'generateScheduleLink');
                        } else {
                            showGenericErrorModal('', 'paymentError');
                        }
                    }
                })
            );
        }
    };

    if (!isMembershipAvailable) {
        return null;
    }

    return (
        <CheckoutLayout
            backgroundImage={backgroundImage}
            nodeTitle={t('pages.requestTelemedicineVisit.title')}
            title={t('pages.requestTelemedicineVisit.title')}
            eyebrowText={t('pages.requestTelemedicineVisit.eyebrow')}
            className="request-visit-layout"
        >
            <div className="request-visit">
                <div className="request-visit-toast-box-wrapper">
                    <ToastBox variant="info">
                        <div className="request-visit-toast-box">
                            <div>
                                <CircleInfo />
                            </div>
                            <p>{t('pages.requestTelemedicineVisit.toast')}</p>
                        </div>
                    </ToastBox>
                </div>

                <CartReviewSectionHeader label={t('pages.requestTelemedicineVisit.patientInformation.title')} />
                <Container fluid>
                    <Row>
                        <Col>
                            {!isErrorMessageVisible && membersPayload && membersPayload.length > 1 && (
                                <p className="manage-group-card-list__container-list__select-patient text-left">
                                    {t('pages.requestTelemedicineVisit.patientInformation.selectPatientInfo')}
                                </p>
                            )}
                            {isErrorMessageVisible && (
                                <p className="manage-group-card-list__container-list__select-patient-error text-left">
                                    {t('pages.requestTelemedicineVisit.patientInformation.errorMessage')}
                                </p>
                            )}

                            {loadingDetails ? (
                                <SpinnerInline />
                            ) : (
                                <ManageGroupCardList
                                    isSingleAccount={membersPayload.length === 1}
                                    payload={membersPayload}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>

                {!requestVisitMemberIdSelected && (
                    <div className="request-visit-total-ctas partial">
                        <Button
                            type="button"
                            variant="text"
                            className="text-cerulean btn-demi"
                            label={t('pages.requestTelemedicineVisit.ctas.back')}
                            onClick={() => {
                                navigate('/secure/membership');
                            }}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                        />
                        <Button
                            type="button"
                            className="sm-full btn-bold"
                            label={t('pages.requestTelemedicineVisit.ctas.continue')}
                            onClick={handleSubmitSelectedMember}
                        />
                    </div>
                )}

                {requestVisitMemberIdSelected && (
                    <>
                        <CartReviewSectionHeader label={healthProfileData.initialTitle} />
                        <HealthConditions
                            isUserHasNotSubmittedConditions={userHasNotSubmittedConditions}
                            existingConditions={existingConditions}
                            existingAllergies={existingAllergies}
                            isUserHasNotSubmittedAllergies={userHasNotSubmittedAllergies}
                            isMembershipHealthConditions={true}
                            isLoadingHealthConditions={isLoadingMembershipHealthConditions}
                            isLoadingAllergies={isLoadingAllergies}
                        />

                        {/* DRX-2286: Payment Methods */}

                        {/* For OnDemand Plan the payment methods would be showed by default */}
                        {isOnDemandPlan && (
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <div className="request-visit-payment-information">
                                            <CheckoutSectionHeader
                                                label={t('pages.requestTelemedicineVisit.paymentInformation.title')}
                                            />

                                            <ToastBox variant="warning">
                                                <div className="request-visit-payment-information-toast-box">
                                                    <AlertIcon variant="warning" />
                                                    <p>
                                                        {defaultCreditCard === undefined
                                                            ? t(
                                                                  'pages.requestTelemedicineVisit.paymentInformation.addPaymentMethod'
                                                              )
                                                            : t(
                                                                  'pages.requestTelemedicineVisit.paymentInformation.onDemandToast'
                                                              )}
                                                    </p>
                                                </div>
                                            </ToastBox>

                                            <CheckoutPaymentMethods
                                                onChange={onChangePaymentMethod}
                                                selectedPaymentMethod={selectedCardForVisit}
                                                creditCardsData={activeCreditCards}
                                                shouldHidePaymentType={true}
                                                error={error}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        {/* For Care and Care Plus the payment methods would be showed if:
                            - The user has no more free visits from freeTelehealthVisitsRemaining
                        */}

                        {!isOnDemandPlan && defaultCreditCard === undefined && hasVisitAvailable && (
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <div className="request-visit-payment-information">
                                            <CheckoutSectionHeader
                                                label={t('pages.requestTelemedicineVisit.paymentInformation.title')}
                                            />

                                            <ToastBox variant="warning">
                                                <div className="request-visit-payment-information-toast-box">
                                                    <AlertIcon variant="warning" />
                                                    <p>
                                                        {t(
                                                            'pages.requestTelemedicineVisit.paymentInformation.addPaymentMethod'
                                                        )}
                                                    </p>
                                                </div>
                                            </ToastBox>

                                            <CheckoutPaymentMethods
                                                onChange={onChangePaymentMethod}
                                                selectedPaymentMethod={selectedCardForVisit}
                                                creditCardsData={activeCreditCards}
                                                shouldHidePaymentType={true}
                                                error={error}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        {!isOnDemandPlan && !hasVisitAvailable && (
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <div className="request-visit-payment-information">
                                            <CheckoutSectionHeader
                                                label={t('pages.requestTelemedicineVisit.paymentInformation.title')}
                                            />

                                            <ToastBox variant="warning">
                                                <div className="request-visit-payment-information-toast-box">
                                                    <AlertIcon variant="warning" />
                                                    {isPlanOwner ? (
                                                        <p>
                                                            {t(
                                                                isCarePlan
                                                                    ? 'pages.requestTelemedicineVisit.paymentInformation.numberVisitsCareToast'
                                                                    : 'pages.requestTelemedicineVisit.paymentInformation.numberVisitsToast'
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {t(
                                                                'pages.requestTelemedicineVisit.paymentInformation.inviteeVisitToast'
                                                            )}
                                                        </p>
                                                    )}
                                                </div>
                                            </ToastBox>

                                            <CheckoutPaymentMethods
                                                onChange={onChangePaymentMethod}
                                                selectedPaymentMethod={selectedCardForVisit}
                                                creditCardsData={activeCreditCards}
                                                shouldHidePaymentType={true}
                                                error={error}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )}

                        {/* DRX-2245: Telemedicine visit total */}
                        <Container fluid>
                            <Row>
                                <Col>
                                    <div className="request-visit-total">
                                        <div className="request-visit-total-info">
                                            <p>{t('pages.requestTelemedicineVisit.totalValue')}</p>

                                            <div className="request-visit-total-info-price">
                                                {/* If the user plan has no more free visits OR the user is onDemand, we'll show the price */}
                                                {hasVisitAvailable === false || isOnDemandPlan ? (
                                                    <p>{formatPrice(membershipObject?.telehealthPrice)}</p>
                                                ) : (
                                                    <>
                                                        <BadgeStatus
                                                            variant="success"
                                                            label={t('pages.requestTelemedicineVisit.includedInPlan')}
                                                        />
                                                        <p>$0.00</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="request-visit-total-ctas">
                                            <Button
                                                type="button"
                                                variant="text"
                                                className="text-cerulean btn-demi"
                                                label={t('pages.requestTelemedicineVisit.ctas.back')}
                                                onClick={() => {
                                                    navigate('/secure/membership');
                                                }}
                                                dataGAFormName="OrderPrescription"
                                                dataGAFormStepName="Checkout"
                                                isBusy={isBusy}
                                                disabled={isBusy}
                                            />
                                            <Button
                                                async
                                                type="button"
                                                variant="primary"
                                                className="sm-full btn-bold"
                                                label={t('pages.requestTelemedicineVisit.ctas.continue')}
                                                disabled={
                                                    userHasNotSubmittedAllergies ||
                                                    userHasNotSubmittedConditions ||
                                                    isBusy
                                                }
                                                onClick={() => {
                                                    handleOnSubmit(
                                                        healthProfileData.patientEpostPatientNum,
                                                        selectedCardForVisit?.cardSeqNum
                                                            ? selectedCardForVisit.cardSeqNum
                                                            : ''
                                                    );
                                                }}
                                                isBusy={isBusy}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </div>
        </CheckoutLayout>
    );
};

export default RequestVisitPage;

export const query = graphql`
    query RequestVisitPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
